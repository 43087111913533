<template>
  <div class="main-plan">
    <div class="plan-content">
      <v-form ref="form" @submit.prevent="saveChange">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
                @keydown.enter="focusNext"
                outlined
                dense
                :label="$t('bank.name')"
                v-model="bank.name"
                :hint="`${server_errors.name}`"
                persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
                @keydown.p.prevent.enter="saveChange"
                outlined
                dense
                :label="$t('bank.short_name')"
                v-model="bank.short_name"
                :hint="`${server_errors.short_name}`"
                persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn class="btn-save-change" @click="saveChange" :loading="btnLoading">
              {{ $t("bank.saveChange") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bank: {},
  },
  data() {
    return {
      btnLoading: false,
      server_errors: {
        name: "",
        short_name: "",
      },
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    saveChange() {
      this.$axios.put(`admin/edit/bank`, {
        id: this.bank.id,
        name: this.bank.name,
        short_name: this.bank.short_name
      }).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$emit("close");
          this.$emit("success")
        }
        this.btnLoading = false;
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style scoped lang="scss">
.main-plan {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .plan-content {
    width: 400px;
    height: 500px;
    // background-color: #719AFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
