<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("bank.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Search.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("bank.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="bankList.length > 0">
          <thead>
          <tr>
            <th class="text-start">
              {{ $t("bank.no") }}
            </th>
            <th class="text-left">
              {{ $t("bank.name") }}
            </th>
            <th class="text-left">
              {{ $t("bank.short_name") }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in bankList" :key="idx">
            <td>
              {{ idx + 1 }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.short_name }}
            </td>

            <td>

            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchBank"
        >
        </Pagination>

        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">
          <template v-slot="{close}">
            <editBank :bank="bank" @close="close" @success="fetchBank"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <deleteBank :bank_id="bank_id" @close="close" @success="fetchBank"/>
          </template>
        </ModalDelete>
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import Loading from "../../../components/Loading";
import editBank from "@/views/MainAdmin/Bank/CRUD/editBank";
import deleteBank from "@/views/MainAdmin/Bank/CRUD/deleteBank";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    editBank,
    deleteBank
  },

  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: false,
      searchItem: "",
      bankList: [],
      bank: {},
      bank_id: "",
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchBank();
    },
    onCreate() {
      this.$router.push({name: 'bank.create'}).catch(() => {
      });
    },

    filterBank(bank_id) {
      return (
          this.bankList.filter((item) => {
            return item.id == bank_id;
          })[0] || {}
      );
    },

    onEdit(bank_id) {
      this.bank = {
        ...this.filterBank(bank_id)
      };
      this.$store.commit("modalEdit_State", true);
    },


    onDeleted(bank_id) {
      this.bank_id = bank_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchBank() {
      this.isLoading = true;
      this.$axios.get(`admin/list/banks`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.status === 200) {
          this.isLoading = false;
          this.bankList = res.data.data.data;
          console.log(this.bankList)
        }
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      })
    }
  },
  created() {
    this.fetchBank();
  }
}
</script>

<style scoped lang="scss">

</style>
